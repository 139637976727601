<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <product-list
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalProductActive"
      :show.sync="show"
      :data.sync="modalProductData"
      :id-product.sync="idProduct"
      :warehouse.sync="from"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="6">
              <h3>{{ $t('Detail') }}</h3>

              <b-row class="p-1">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Code')"
                    label-for="register-code"
                  >
                    <b-form-input
                      id="register-code"
                      v-model="code"
                      name="register-code"
                      :placeholder="$t('Code')"
                    />

                    <small class="text-secondary">
                      *{{
                        $t(`If not specified, the warehouse will
                          be added automatically`)
                      }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('From')"
                    label-for="register-from"
                  >
                    <v-select
                      id="register-from"
                      v-model="from"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="respDataWarehouse"
                      :reduce="respDataWarehouse => respDataWarehouse._id"
                      class="select-size-lg"
                      @input="queryStock($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('To')"
                    label-for="register-to"
                  >
                    <v-select
                      id="register-to"
                      v-model="to"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="respDataWarehouse"
                      :reduce="respDataWarehouse => respDataWarehouse._id"
                      class="select-size-lg"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Code')"
                    label-for="register-code"
                  >
                    <flat-pickr
                      id="date"
                      v-model="date"
                      class="form-control"
                    />
                    <small class="text-secondary">
                      *{{
                        $t(
                          `If no date is selected, it will be set to the current date`,
                        )
                      }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Remark')"
                    label-for="register-remark"
                  >
                    <b-form-input
                      id="register-remark"
                      v-model="remark"
                      name="register-remark"
                      :placeholder="$t('Remark')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card
        no-body
        class="mb-2"
      >
        <div class="m-2">
          <b-row class="mb-0">
            <b-col>
              <h3>{{ $t('Product') }}</h3>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="modalAdjust()"
                >
                  <span class="align-middle">{{ $t('Select Product') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="p-1">
            <b-col cols="12">
              <b-table
                striped
                responsive
                class="mb-0"
                :items="table"
                :fields="tableColumns"
                style="height: 60vh;"
              >
                <template #cell(code)="data">
                  <p>{{ data.item.code }}</p>
                </template>
                <template #cell(name)="data">
                  <p>{{ showFromCurrentLanguage(data.item.name) }}</p>
                </template>
                <template #cell(amount)="data">
                  <cleave
                    v-model="table[data.index].amount"
                    class="form-control"
                    :raw="false"
                    :options="optionCleave.number"
                    :placeholder="$t('Amount transferred')"
                  />
                  <small class="text-danger">
                    *{{ $t(`Current inventory amount`) }}
                    {{ table[data.index].maxAmount }}
                  </small>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-row class="mb-0">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-dark"
            class="d-flex"
            @click="$router.go(-1)"
          >
            <span class="align-middle">{{ $t('Back') }}</span>
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="b-col-custom"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-flex"
              @click="validationForm()"
            >
              <span class="align-middle">{{ $t('Save') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile,
  BCardGroup,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BImg,
  BForm,
  BAvatar,
  BFormInput,
  BButton,
  BFormGroup,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/warehouseProduct'
import storeModuleWarehouseAndBranch from '@/store/services/eCommerce/warehouseAndBranch'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import debounce from 'debounce'
import ProductList from './components/ProductList.vue'

const STORE_MODULE_NAME = 'warehouseProduct'
const STORE_MODULE_NAME_WAREHOUSE = 'warehouseAndBranch'

export default {
  components: {
    BFormFile,
    BCardGroup,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BFormGroup,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    flatPickr,
    Cleave,
    ProductList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      avatarText,
      code: '',
      from: '',
      to: '',
      date: new Date(),
      remark: '',
      table: [], // ตารางการจัดส่ง
      idProduct: '',
      isModalProductActive: false,
      // modalProductData: [],
      show: false,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('Code'), sortable: false },
        { key: 'name', label: this.$t('Product name'), sortable: false },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
      ]
    },
    respDataWarehouse() {
      return store.state[STORE_MODULE_NAME_WAREHOUSE].respData != null
        ? store.state[STORE_MODULE_NAME_WAREHOUSE].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)} (${this.$t(
            e.type,
          )})`,
        }))
        : []
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    modalProductData() {
      return this.table || []
    },
  },
  watch: {
    async idProduct(val) {
      if (val) {
        this.viewProduct()
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_WAREHOUSE)) {
      store.registerModule(
        STORE_MODULE_NAME_WAREHOUSE,
        storeModuleWarehouseAndBranch,
      )
    }
    if (this.$route.query.to) {
      const data = this.$route.query.to
      this.to = data
    }

    if (this.$route.params.id !== 'add') {
      if (this.$route.params.id.length > 0) {
        this.idProduct = this.$route.params.id
        // this.viewProduct()
      }
    }
    if (this.$route.query.from) {
      const data = this.$route.query.from
      this.from = data
    }
    const obj = {
      currentPage: 0,
      pageSize: 0,
      searchQuery: '',
    }

    store
      .dispatch(`${STORE_MODULE_NAME_WAREHOUSE}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_WAREHOUSE)) store.unregisterModule(STORE_MODULE_NAME_WAREHOUSE)
  },
  setup() {
    return {
      STORE_MODULE_NAME,
      STORE_MODULE_NAME_WAREHOUSE,
    }
  },
  methods: {
    modalAdjust() {
      // this.modalProductData = this.table
      this.isModalProductActive = true
    },
    viewProduct() {
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, { id: this.idProduct })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          const { data } = result.data
          this.show = false
          this.table = []
          for (let index = 0; index < data.length; index++) {
            this.table.push({
              _id: data[index]._id,
              code: data[index].code,
              name: data[index].name,
              amount: 0,
              maxAmount: 0,
            })
          }
          if (this.from) {
            this.queryStock(this.from)
          }

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    queryStock(data) {
      const obj = {
        warehouseId: data,
        products: this.table.map(e => e._id),
      }
      this.show = true

      store
        .dispatch(`${STORE_MODULE_NAME}/checkStock`, obj)
        .then(result => {
          this.show = false
          const { data } = result.data
          for (let index = 0; index < data.length; index++) {
            const indexTable = this.table.findIndex(
              e => e._id.toString() === data[index].warehouseProductId.toString(),
            )
            if (indexTable > -1) {
              this.table[indexTable].amount = data[index].stock
              this.table[indexTable].maxAmount = data[index].stock
            }
          }
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          this.show = false
          // this.showToast('danger', 'bottom-right')
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    validationForm() {
      const obj = {
        code: this.code,
        from: this.from,
        to: this.to,
        date: this.date,
        remark: this.remark,
      }

      if (this.table.every(e => e.maxAmount >= e.amount && e.amount > 0)) {
        obj.products = this.table.map(e => ({
          _id: e._id,
          stock: e.amount,
        }))
        this.show = true

        store
          .dispatch(`${STORE_MODULE_NAME}/transfer`, obj)
          .then(result => {
            this.show = false
            this.$router.go(-1)

            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.show = false
            // this.showToast('danger', 'bottom-right')
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(
              'Please check the quantity before clicking to confirm',
            ),
          },
        })
      }
      console.log(obj)
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}

.card-image-custom {
  text-align: center;
  margin: 8px;
  border: 1px solid rgb(202, 202, 202);

  border-radius: 10px;
}

.image-card-image-custom {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.image-custom {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
</style>
